.DrawingContest {
    .intro {
        a {
            @apply tw-text-brand hover:tw-underline;
        }
    }

    .list {
        column-count: 1;

        @media screen and (min-width: theme("screens.md")) {
            column-count: 2;
            column-gap: theme("spacing.5");
        }
    }

    .DrawingContestSubmission {
        break-inside: avoid;
    }
}
